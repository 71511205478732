
import './App.css';
import {Switch, Route, Redirect} from 'react-router-dom';
import Home from './views/home';
import Header from './components/header';
import Footer from './components/footer';

function App() {

  return (
    <div>
      <Header/>
      <div>
        <Switch>
          <Route path="/" exact component={Home}/>
{/*           this is just here as an example */}
{/*           <ProtectedRoute path="/profile" component={Profile}/> */}
          <Redirect from="*" to="/" />
        </Switch>
      </div>
      <Footer/>
    </div>
  );

}

export default App;
