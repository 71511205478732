
import React from 'react';

const Footer = () => {

  return (
                <div className="app-footer">
                  <a href="/">
                    <img className="footer-img" src="blacknemojr.png" alt="footer"/>
                  </a>
                </div>
  );

};

export default Footer;