
import React from 'react';

const LeftSideMenu = (props) => {

    const handleButtonClick = (event) => {
      console.info("left-side-menu event "+ event.target.value);
      props.onClick(event.target.value);
      event.preventDefault();
    }

    const data = [
           {
             label: "Home",
             url: "https://home.blacknemojr.com"
           }
         ]


    return (
      <div className="left-side-menu">
        {data.map((data) => {
          return (
            <button onClick={handleButtonClick} key={data.label} value={data.url}>{data.label}
            </button>
          );
        })}
      </div>
    );
};

export default LeftSideMenu;