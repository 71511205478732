
import React from 'react';

const ExternalApp = (props) => {

  return (
    <div id="external-app">
      <div>do something with link : {props.link}</div>
      <a href={props.link} target="_blank" rel="noreferrer">{props.link}</a>
    </div>
  );
};

export default ExternalApp;