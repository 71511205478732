
import React from 'react';

const Header = () => {

  return (
    <div className="App-header">
      <a href="/">
        <img className="header-img" src="blacknemojr.png" alt="header"/>
      </a>
    </div>
  );

};

export default Header;