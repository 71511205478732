
import React from 'react';

const NavBar = (props) => {

  const handleNavBarClick = (event) => {
    console.info("handleNavBarClick = " + event.target.value);
    props.mainNavCallback(event.target.value);
    event.preventDefault();
  }

  return (
    <div className="nav-bar">
      <button onClick={handleNavBarClick} value='home'>Home</button>
      <button onClick={handleNavBarClick} value='profile'>Profile</button>
    </div>
  );
};

export default NavBar;