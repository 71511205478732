
import {React, useState} from 'react';
import Links from '../components/links';
import MainNav from '../components/main-nav';
import { useAuth0 } from '@auth0/auth0-react';
import AuthenticationButton from '../components/authentication-button';
import Profile from './profile';
import ExternalApp from '../components/external-app';
import LeftSideMenu from '../components/left-side-menu';


const Home = () => {
  const {isAuthenticated} = useAuth0();
  const [currentView, setCurrentView] = useState("home");
  const [currentAppUrl, setCurrentAppUrl] = useState("");

  const handleHomeCallback = (view) => {
    console.info("home.jsx handleHomeCallback = " + view);
    setCurrentView(view);
  }
  const handleLinkClick = (appUrl) => {
    console.info("home.jsx handleLinkClick : appUrl = " + appUrl);
    setCurrentAppUrl(appUrl);
  }

  if (isAuthenticated) {
    if (currentView === "home") {
      console.info("home.js = home");
      return (
        <div>
          <MainNav className="main-nav" homeCallback={handleHomeCallback}/>
          <div className="body-container">
            <LeftSideMenu className="left-nav-menu" onClick={handleLinkClick}/>
            {currentAppUrl ? <ExternalApp className="main-content" link={currentAppUrl}/> : <div/> }
          </div>
        </div>
      );
    } else if (currentView === "profile") {
      console.info("home.js = profile");
      return (
        <div>
          <MainNav homeCallback={handleHomeCallback}/>
          <Profile/>
        </div>
      );
    } else {
      console.info("home.js = something else");
      return (
        <div>
          <MainNav homeCallback={handleHomeCallback}/>
        </div>
      );
    }
  } else {
    return <AuthenticationButton/>
  }
};

export default Home;