
import React from 'react';
import NavBar from './nav-bar';
import AuthenticationButton from './authentication-button';

const MainNav = (props) => {

  const handleMainNavCallback = (view) => {
    console.info("handleMainNavCallback - " + view);
    props.homeCallback(view);
  }

  return (
    <div>
      <nav className="main-nav">
        <NavBar mainNavCallback={handleMainNavCallback}/>
        <AuthenticationButton/>
      </nav>
    </div>
  );

};

export default MainNav;